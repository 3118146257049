































































import { Component, Prop, Vue } from 'vue-property-decorator'
import NavMenuBar from '@/components/NavMenuBar.vue'
import { IDropDown } from '../../NewFeed/Layout/NewFeedLayout.vue'

@Component({ components: { NavMenuBar } })
export default class SidebarPc extends Vue {
  @Prop() private roleEditFile!: boolean
  @Prop() private folderSelected!: any
  @Prop() private folderOptions!: IDropDown[]

  genRoute(folder: any) {
    const getFolder = () => {
      switch (folder.id) {
        case 0: return 'all'
        case 1: return 'unclassified'
        case 2: return 'bin'
        case 3: return 'attach-file'
        default: return folder.value.id
      }
    }

  this.$route.query.folder
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: { folder: getFolder() }
    }
  }

  handleSelectFolder(event: any, index: number) {
    if(!event.ctrlKey) {
      this.$emit('handleSelectFolder', index)
    }
  }

  openEditFolderModal(index: number) {
    this.$emit('openEditFolderModal', index)
  }
}
