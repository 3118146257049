
































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ModalAddFolder from '@/components/Modal/ModalAddFolder.vue'
import ModalMoveToFolder from '@/components/Modal/ModalMoveToFolder.vue'
import ModalEditFolder from '@/components/Modal/ModalEditFolder.vue'
import SharedFolderList from '@/views/Group/TopPage/SharedFolder/SharedFolderList.vue'
import SidebarPc from '@/views/Group/TopPage/SharedFolder/Layout/SidebarPc.vue'
import PcButtonArea from '@/views/Group/TopPage/SharedFolder/Layout/PcButtonArea.vue'
import MobileButtonArea from '@/views/Group/TopPage/SharedFolder/Layout/MobileButtonArea.vue'

import {
  EDeleteFileType,
  EFileBelong,
  EFolderItemCountType,
  EFolderType,
  FileCompact,
  IdName,
  IFolderItemCount,
  MoveToUnclsFrom
} from '@/models'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import GroupService from '@/services/GroupService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import CheckDevice from '@/helpers/CheckDevice'
import { EListSort } from '@/models/Setting/Enum'

import store from '@/store'
import Setting from '@/store/modules/Setting'
import { getModule } from 'vuex-module-decorators'
const SettingModule = getModule(Setting, store)
/**
 * f2-601
 * f2-703
 * f2-601b1
 * グループ共有フォルダTOP
 * 共通トピック共有フォルダ
 * グループ共有フォルダ - ゴミ箱
 */
@Component({
  components: {
    SidebarPc,
    PcButtonArea,
    MobileButtonArea,
    SharedFolderList,
    ModalAddFolder,
    ModalMoveToFolder,
    ModalEditFolder
  }
})
export default class SharedFolderLayout extends Vue {
  private startLoading: boolean = true
  private isCommonTopic = !this.$route.params.groupId
  private roleEditFile: boolean = false
  private title: string = this.$t('groups.shared_folder.title') as string
  private sortSelected: any = {}
  private sortOptions: IDropDown[] = []
  private allFolder: IdName[] = []
  private folderSelected: any = {
    text: `${this.$t('groups.all')}`,
    suffix: ' (0)'
  }
  private folderEdit: any = {}
  private folderOptions: IDropDown[] = []
  private files: FileCompactViewModel[] = []
  private checkAll: boolean = false
  private isSelectAnyItem: boolean = false
  private actionAreaState: EState = EState.ALL
  private modalMess: string = ''
  private isDeleteAllBin: boolean = false
  private eState = EState
  private page: number = 1
  private maxPage: number = 1
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private folderItemCount: IFolderItemCount[] = []

  async created() {
    this.initSortDropdown()
    this.initFolder()
    this.handleRoleEditFile()
  }

  @Watch('sortSelected')
  @Watch('folderSelected')
  init() {
    this.page = 1
    if (this.isCommonTopic) {
      SettingModule.CHANGE_SORT_COMMON_SHARED_FOLDER(this.sortSelected.id)
      if (this.sortSelected.id !== undefined) {
        this.allFileCommonTopic()
        this.title = this.$t('groups.shared_folder.title') as string
      }
    } else {
      SettingModule.CHANGE_SORT_GROUP_SHARED_FOLDER(this.sortSelected.id)
      if (
        this.folderSelected.id !== undefined &&
        this.sortSelected.id !== undefined
      ) {
        this.checkAll = false
        this.isSelectAnyItem = false
        switch (this.folderSelected.id) {
          case 0:
            this.actionAreaState = EState.ALL
            this.allFileNotDelete()
            this.title = this.$t('groups.shared_folder.title') as string
            this.$router.replace({ query: { folder: 'all' } }).catch(() => {})
            break
          case 1:
            this.actionAreaState = EState.ALL
            this.allFileUnclassified()
            this.title = this.$t('groups.shared_folder.title') as string
            this.$router
              .replace({ query: { folder: 'unclassified' } })
              .catch(() => {})
            break
          case 2:
            this.actionAreaState = EState.BIN
            this.allFileDeleted()
            this.title = this.$t('groups.bin') as string
            this.$router.replace({ query: { folder: 'bin' } }).catch(() => {})
            break
          case 3:
            this.actionAreaState = EState.ATTACH_FILE
            this.allAttachFile()
            this.title = this.$t('groups.attach_file') as string
            this.$router
              .replace({ query: { folder: 'attach-file' } })
              .catch(() => {})
            break
          default:
            this.actionAreaState = EState.FOLDER
            this.allFileByFolder()
            this.title = this.folderSelected.text
            this.$router
              .replace({
                query: { folder: `${this.folderSelected.value.id}` }
              })
              .catch(() => {})
            break
        }
      }
    }
  }

  @Watch('$store.state.userInfo.user.group_role')
  handleRoleEditFile() {
    if (!this.isCommonTopic) {
      this.roleEditFile = this.$store.state.userInfo.user.group_role.role_edit_shared_folder
    }
  }

  async getSharedFolderFolderItemCount() {
    if (!this.isCommonTopic) {
      return GroupSharedFolderService.getSharedFolderFolderItemCount(
        this.$route.params.groupId
      )
        .then(res => {
          if (res.status === 200) {
            this.folderItemCount = res.data
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('shared-folder-error-modal')
        })
    }
  }

  /**
   * Call API for files that not detele
   */
  allFileNotDelete(loadMore?: boolean) {
    GroupSharedFolderService.getFileNotDelete({
      group_id: Number(this.$route.params.groupId),
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit,
      unread_only: '0'
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.files = [
              ...this.files,
              ...res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            ]
          } else {
            this.files = res.data.data.map(
              (item: any) => new FileCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('shared-folder-error-modal')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for files of unclassified
   */
  allFileUnclassified(loadMore?: boolean) {
    GroupSharedFolderService.getFileUnclassified({
      group_id: Number(this.$route.params.groupId),
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.files = [
              ...this.files,
              ...res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            ]
          } else {
            this.files = res.data.data.map(
              (item: any) => new FileCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('shared-folder-error-modal')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for files deleted
   */
  allFileDeleted(loadMore?: boolean) {
    if (this.roleEditFile) {
      GroupSharedFolderService.getFileDeleted({
        group_id: Number(this.$route.params.groupId),
        order_by: this.sortSelected.value.order_by,
        sorted_by: this.sortSelected.value.sorted_by,
        page: this.page,
        limit: this.limit
      })
        .then(res => {
          if (res.status === 200) {
            if (loadMore) {
              this.files = [
                ...this.files,
                ...res.data.data.map(
                  (item: any) => new FileCompactViewModel(item)
                )
              ]
            } else {
              this.files = res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            }
            this.maxPage = res.data.last_page
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => {
          this.startLoading = false
        })
    }
  }

  /**
   * Call API for attach files
   */
  allAttachFile(loadMore?: boolean) {
    GroupSharedFolderService.getAttachFiles({
      group_id: Number(this.$route.params.groupId),
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.files = [
              ...this.files,
              ...res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            ]
          } else {
            this.files = res.data.data.map(
              (item: any) => new FileCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('shared-folder-error-modal')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for files by folder
   */
  allFileByFolder(loadMore?: boolean) {
    if (!this.isCommonTopic) {
      GroupSharedFolderService.getFileByFolder({
        group_id: Number(this.$route.params.groupId),
        order_by: this.sortSelected.value.order_by,
        sorted_by: this.sortSelected.value.sorted_by,
        page: this.page,
        limit: this.limit,
        folder_id: this.folderSelected.value.id
      })
        .then(res => {
          if (res.status === 200) {
            if (loadMore) {
              this.files = [
                ...this.files,
                ...res.data.data.map(
                  (item: any) => new FileCompactViewModel(item)
                )
              ]
            } else {
              this.files = res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            }
            this.maxPage = res.data.last_page
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('shared-folder-error-modal')
            this.initFolder()
          }
        })
        .finally(() => {
          this.startLoading = false
        })
    }
  }

  /**
   * Call API for files common topic
   */
  allFileCommonTopic(loadMore?: boolean) {
    CommonSharedFolderService.getAllFileCommonTopic({
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.files = [
              ...this.files,
              ...res.data.data.map(
                (item: any) => new FileCompactViewModel(item)
              )
            ]
          } else {
            this.files = res.data.data.map(
              (item: any) => new FileCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('shared-folder-error-modal')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page
    this.checkAll = false
    if (this.isCommonTopic) {
      this.allFileCommonTopic(true)
    } else {
      switch (this.folderSelected.id) {
        case 0:
          this.allFileNotDelete(true)
          break
        case 1:
          this.allFileUnclassified(true)
          break
        case 2:
          this.allFileDeleted(true)
          break
        case 3:
          this.allAttachFile(true)
          break
        default:
          this.allFileByFolder(true)
          break
      }
    }
  }

  /**
   * Init sort dropdown
   */
  initSortDropdown() {
    const data = [
      {
        id: EListSort.UPDATE_DESC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.UPDATE_ASC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.asc')
      },
      {
        id: EListSort.TITLE_DESC,
        value: {
          order_by: 'title',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.TITLE_ASC,
        value: {
          order_by: 'title',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.asc')
      }
    ]
    this.sortOptions = data
    if (this.isCommonTopic) {
      this.sortSelected =
        data[this.$store.state.setting.user_sort.common_shared_folder]
    } else {
      this.sortSelected =
        data[this.$store.state.setting.user_sort.group_shared_folder]
    }
  }

  /**
   * Get count item for each folder
   */
  getCount(type: EFolderItemCountType, folderId?: number) {
    const item = this.folderItemCount.find(
      item =>
        item.type === type && (folderId ? item.folder_id === folderId : true)
    )
    return item && item.count ? item.count : 0
  }

  /**
   * Init folder dropdown
   */
  async initFolder() {
    if (!this.isCommonTopic) {
      await this.getSharedFolderFolderItemCount()
      GroupSharedFolderService.getAllFolderOfSharedFolder(
        this.$route.params.groupId
      )
        .then(res => {
          if (res.status === 200) {
            this.allFolder = res.data
            let defaultOptions = [
              {
                id: 0,
                value: 0,
                text: `${this.$t('groups.all')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.ALL)})`
              },
              {
                id: 1,
                value: 1,
                text: `${this.$t('groups.unclassified')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.UNCLASSIFIED)})`
              }
            ]

            //add bin if current user have role
            if (this.roleEditFile) {
              defaultOptions.push({
                id: 2,
                value: 2,
                text: `${this.$t('groups.bin')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.IN_BIN)})`
              })
            }

            defaultOptions.push({
              id: 3,
              value: 3,
              text: `${this.$t('groups.attach_file')}`,
              suffix: ` (${this.getCount(EFolderItemCountType.ATTACH_FILE)})`
            })

            const folders = res.data.map((item: IdName, index: number) => {
              return {
                id: index + 4,
                value: item,
                text: `${item.name}`,
                suffix: ` (${this.getCount(
                  EFolderItemCountType.FOLDER,
                  item.id
                )})`
              }
            })
            const mergeDatas = [...defaultOptions, ...folders]
            this.folderOptions = mergeDatas
            this.handleFolderSelected()
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('shared-folder-error-modal')
        })
    }
  }

  /**
   * Hanlde folder selected by query folder
   */
  handleFolderSelected() {
    if (this.$route.query.folder) {
      switch (this.$route.query.folder) {
        case 'all':
          this.folderSelected = this.folderOptions[0]
          break
        case 'unclassified':
          this.folderSelected = this.folderOptions[1]
          break
        case 'bin':
          this.folderSelected = this.folderOptions[2]
          break
        case 'attach-file':
          this.folderSelected = this.roleEditFile
            ? this.folderOptions[3]
            : this.folderOptions[2]
          break
        default:
          //get current selected folder id
          const folderSelectedId = Number(this.$route.query.folder)

          //if current selected folder id exist
          if (folderSelectedId) {
            const newFolderSelected = this.folderOptions.find(
              item => item.value.id === folderSelectedId
            )
            if (newFolderSelected) {
              this.folderSelected = newFolderSelected
            } else {
              this.folderSelected = this.folderOptions[0]
            }

            //if current selected folder id not exist
          } else {
            this.folderSelected = this.folderSelected.value
              ? this.folderOptions[this.folderSelected.value]
              : this.folderOptions[0]
          }
      }
    } else {
      this.folderSelected = this.folderOptions[0]
      this.$router.replace({ query: { folder: 'all' } }).catch(() => {})
    }
  }

  /**
   * Handle checkbox check all
   */
  handleCheckAll(checkAll: boolean) {
    this.checkAll = checkAll
    if (this.checkAll) {
      this.files.forEach(item => (item.checked = true))
      this.hanldeSelectAnyItem(true)
    } else {
      this.files.forEach(item => (item.checked = false))
      this.hanldeSelectAnyItem(false)
    }
  }

  /**
   * Handle is any checkbox is check
   */
  hanldeSelectAnyItem(isSelect: boolean) {
    this.isSelectAnyItem = isSelect
  }

  /**
   * Handle move file from  selected folder to unclassified
   * Call API move to selected folder
   */
  handleMoveToUnclassified(isFromBin: boolean) {
    if (this.isSelectAnyItem && this.roleEditFile) {
      this.$blockui.show()
      GroupSharedFolderService.moveFilesToUnclassified(
        this.$route.params.groupId,
        this.getSelectedFileId(),
        isFromBin ? MoveToUnclsFrom.BIN : MoveToUnclsFrom.FOLDER
      )
        .then(res => {
          if (res.status === 200) {
            const newList = this.files.filter(
              file => !this.getSelectedFileId().includes(file.id)
            )
            this.files = [...newList]
            this.isSelectAnyItem = false
            this.checkAll = false
          }
          this.initFolder()
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.init()
          } else {
            this.modalMess = this.$t('common.message.uncatch_error') as string
          }
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle move file from unclassified to selected folder
   * Call API move to selected folder
   */
  handleMoveToFolder(selectedFolder: IdName) {
    if (this.isSelectAnyItem && this.roleEditFile) {
      if (
        this.files
          .filter(item => item.checked)
          .some(item => item.belong_to === EFileBelong.TOPIC)
      ) {
        this.modalMess = this.$t(
          'common.message.attach_folder_dont_move'
        ) as string
        this.$bvModal.show('shared-folder-info-modal')
        return
      }
      this.$blockui.show()
      GroupSharedFolderService.moveFilesToFolder({
        group_id: this.$route.params.groupId,
        file_ids: this.getSelectedFileId(),
        folder_id: selectedFolder.id
      })
        .then(res => {
          if (res.status === 200) {
            if (this.folderSelected.value !== 0) {
              const newList = this.files.filter(
                file => !this.getSelectedFileId().includes(file.id)
              )
              this.files = [...newList]
            } else {
              this.files.forEach(item => (item.checked = false))
            }

            this.isSelectAnyItem = false
            this.checkAll = false
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            if (err.response.data.message === 'file_not_found') {
              this.modalMess = this.$t(
                'common.message.file_not_exist'
              ) as string
            } else if (err.response.data.message === 'folder_not_found') {
              this.modalMess = this.$t(
                'common.message.folder_not_exist'
              ) as string
            }
            this.$bvModal.show('shared-folder-error-modal')
          }
        })
        .finally(() => {
          this.$blockui.hide()
          this.initFolder()
        })
    }
  }

  /**
   * Jump to create new file page
   */
  jumpToCreateFilePage() {
    this.$router.push({
      name: 'group-shared-folder-file-create',
      params: {
        groupId: this.$route.params.groupId,
        folderId: this.$route.query.folder as string
      }
    })
  }

  routerCreateFilePage() {
    return {
      name: 'group-shared-folder-file-create',
      params: {
        groupId: this.$route.params.groupId,
        folderId: this.$route.query.folder as string
      }
    }
  }

  /**
   * Handle select sort
   */
  handleSortChange(index: number) {
    this.sortSelected = this.sortOptions[index]
  }

  openEditFolderModal(index?: number) {
    this.folderEdit = index ? this.folderOptions[index] : this.folderSelected
    this.$bvModal.show('edit-folder')
  }

  /**
   * Handle delete files
   * Call API delete files
   */
  handleMoveFileToBin() {
    if (this.isSelectAnyItem && this.roleEditFile) {
      this.$blockui.show()
      GroupSharedFolderService.deleteFileOrMoveFileToBin({
        group_id: this.$route.params.groupId,
        file_ids: this.getSelectedFileId(),
        delete_type: EDeleteFileType.MOVE_TO_BIN
      })
        .then(res => {
          if (res.status === 200) {
            const newList = this.files.filter(
              file => !this.getSelectedFileId().includes(file.id)
            )
            this.files = [...newList]
            this.isSelectAnyItem = false
            this.checkAll = false
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.allFileDeleted()
          } else {
            this.modalMess = this.$t('common.message.delete_fail') as string
          }
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * open modal confirm for delete files in bin
   */
  openConfirmDeleteBin(isEmptyBin: boolean) {
    if (isEmptyBin) {
      this.modalMess = this.$t('common.message.delete_all') as string
    } else {
      this.modalMess = this.$t('common.message.delete') as string
    }
    this.isDeleteAllBin = isEmptyBin
    this.$bvModal.show('modal-confirm')
  }

  /**
   * Handle delete files in bin
   *
   * if (isDeleteAllBin === true)
   * Call API empty bin
   *
   * else
   * Call API delete files in bin
   *
   */
  handleDeleteBin() {
    this.$blockui.show()
    if (this.isDeleteAllBin && this.roleEditFile) {
      GroupSharedFolderService.emptySharedFolderBin(this.$route.params.groupId)
        .then(res => {
          if (res.status === 200) {
            this.files = []
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.init()
          } else {
            this.modalMess = this.$t('common.message.delete_fail') as string
          }
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupSharedFolderService.deleteFileOrMoveFileToBin({
        group_id: this.$route.params.groupId,
        file_ids: this.getSelectedFileId(),
        delete_type: EDeleteFileType.HARD_DELETE
      })
        .then(res => {
          if (res.status === 200) {
            const newList = this.files.filter(
              file => !this.getSelectedFileId().includes(file.id)
            )
            this.files = [...newList]
            this.isSelectAnyItem = false
            this.checkAll = false
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.file_not_exist') as string
            this.init()
          } else {
            this.modalMess = this.$t('common.message.delete_fail') as string
          }
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle download files
   * Call API download files
   */
  handleDownloadFiles() {
    const selectedFiles = this.files.filter(item => item.checked)
    if (!selectedFiles.length) return
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.downloadCommonFiles(
        selectedFiles.map(item => item.id)
      )
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, selectedFiles)
          }
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.modalMess = this.$t('common.message.over_500_mb') as string
            this.$bvModal.show('shared-folder-error-modal')
          }
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupService.downloadFiles(
        this.$route.params.groupId,
        selectedFiles.map(item => item.id)
      )
        .then(res => {
          if (res.status === 200) {
            this.hanldeDownloadFile(res, selectedFiles)
          }
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.modalMess = this.$t('common.message.over_500_mb') as string
            this.$bvModal.show('shared-folder-error-modal')
          }
        })
        .finally(() => this.$blockui.hide())
    }
  }

  hanldeDownloadFile(res: any, selectedFiles: FileCompactViewModel[]) {
    const bytes = new Uint8Array(res.data)

    //get filename
    let fileName = selectedFiles[0].name
    if (selectedFiles.length !== 1) {
      const currentDate = new Date().getTime()
      fileName = `dowload-${currentDate}.zip`
    }

    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      let i = bytes.length
      const binaryString = new Array(i)
      while (i--) {
        binaryString[i] = String.fromCharCode(bytes[i])
      }
      const data = binaryString.join('')
      const base64 = window.btoa(data)
      const url = `data:application/zip;charset=utf-8;base64,${base64}`
      window.open(url)
      return
    }

    //download other
    const element = document.createElement('a')
    const blob = new Blob([bytes], { type: '*' })
    element.href = window.URL.createObjectURL(blob)
    element.setAttribute('download', fileName)
    element.click()
  }

  /**
   * Handle create new folder
   * Call API create new folder
   */
  handleAddFolder(newName: string) {
    if (this.roleEditFile) {
      this.$blockui.show()
      GroupSharedFolderService.addNewFileFolder(
        this.$route.params.groupId,
        newName
      )
        .then(res => {
          if (res.status === 201) {
            this.initFolder()
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('shared-folder-error-modal')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle rename selected folder
   * Call API rename selected folder
   */
  handleRenameFolder(newName: string) {
    if (this.roleEditFile) {
      this.$blockui.show()
      GroupService.renameFolder({
        group_id: this.$route.params.groupId,
        id: this.folderEdit.value.id,
        folder_name: newName
      })
        .then(res => {
          if (res.status === 200) {
            if (this.folderEdit.value.id === this.folderSelected.value.id) {
              this.title = newName
              this.folderSelected.text = newName
              this.folderSelected.value.name = newName
            } else {
              const editFolder = this.folderOptions.find(
                item => item.id === this.folderEdit.id
              )
              if (editFolder) {
                editFolder.text = newName
                editFolder.value.name = newName
              }
            }
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('shared-folder-error-modal')
          }
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle delete selected folder
   * Call API delete selected folder
   */
  handleDeleteFolder() {
    if (this.roleEditFile) {
      this.$blockui.show()
      GroupService.deleteFolder({
        group_id: this.$route.params.groupId,
        folder_id: this.folderSelected.value.id,
        type: EFolderType.SHARED_FOLDER
      })
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('shared-folder-error-modal')
          }
        })
        .finally(() => {
          this.initFolder()
          this.$blockui.hide()
        })
    }
  }

  /**
   * Handle select folder
   */
  handleSelectFolder(index: number) {
    this.folderSelected = this.folderOptions[index]
  }

  /**
   * Get selected files
   */
  getSelectedFileId(): number[] {
    return this.files.filter(item => item.checked).map(item => item.id)
  }
}

export class FileCompactViewModel extends FileCompact {
  checked: boolean = false

  constructor(init: FileCompact) {
    super()
    Object.assign(this, init)
  }
}

export interface IDropDown {
  id: number
  value: any
  text: string
  suffix?: string
}

export enum EState {
  ALL, // UNCLASSIFIED also = ALL
  FOLDER,
  BIN,
  ATTACH_FILE
}
