var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[(!_vm.files.length && !_vm.startLoading)?_c('div',{staticClass:"text-center text-secondary pt-6"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_data'))+" ")]):_vm._e(),_vm._l((_vm.files),function(file,index){return _c('div',{key:file.id,staticClass:"mx-xl-5 hover-bg-secondary-deep-less",class:{ 'bg-yellow-tint': !file.is_read && !_vm.isFromHome }},[_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': !_vm.isFromHome }}),_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.isFromHome)?_c('label',{staticClass:"d-flex flex-column justify-content-between align-items-center px-2",class:{ 'pe-10 pe-xl-11 ps-0': _vm.forGroupTop },attrs:{"for":("checkbox-" + index)}},[(_vm.getFileFormat(file.extension) === _vm.eFileFormat.IMAGE)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(file.small_path),expression:"file.small_path"}],staticClass:"rounded-1 img-cover cursor-pointer cursor-pointer flex-fixed h--7 w--7 h-xl--9 w-xl--9 mb-1",class:{ 'ms-3': _vm.forGroupTop }}):_c('div',{class:{ 'ms-2': _vm.forGroupTop }},[_c('i',{class:("fs-28 fs-xl-33 text-primary h--7 w--7 h-xl--9 w-xl--9 text-center " + (_vm.getIconName(
                file.extension
              )))})]),(!_vm.isCommonTopic && !_vm.disableCheckbox)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(file.checked),expression:"file.checked"}],staticClass:"form-check-input mt-1",attrs:{"id":("checkbox-" + index),"type":"checkbox"},domProps:{"checked":Array.isArray(file.checked)?_vm._i(file.checked,null)>-1:(file.checked)},on:{"change":[function($event){var $$a=file.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(file, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(file, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(file, "checked", $$c)}},_vm.handleCheckItem]}}):_vm._e()]):_c('router-link',{staticClass:"d-flex flex-column justify-content-between px-3",attrs:{"to":_vm.jumpToFileDetailPage(index, false)}},[_c('div',{staticClass:"flex-center"},[_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":file.group_avatar
                ? file.group_avatar
                : _vm.isHomeCommon
                ? _vm.commonTopicAvatar
                : _vm.noGroupAvatar}}),_c('i',{staticClass:"fas fa-folder text-primary fs-24 fs-xl-30"})])]),_c('div',{staticClass:"pe-3 py-2 w-100 cursor-pointer d-xl-flex justify-content-xl-between py-xl-4"},[_c('router-link',{staticClass:"content pe-13 mb-1 w-xl-50",attrs:{"to":_vm.jumpToFileDetailPage(index, false)}},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(file.title ? file.title : file.name),expression:"file.title ? file.title : file.name",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(file.title ? file.title : file.name))+" ")])]),_c('div',{staticClass:"text-line-clamp-1 h--8 pre-wrap-all"},[(file.memo ? true : file.content ? file.content : false)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(file.memo ? file.memo : file.content),expression:"file.memo ? file.memo : file.content",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1"},[_vm._v(" "+_vm._s(_vm.handleLongText(file.memo ? file.memo : file.content))+" ")]):_c('span',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.$t('common.message.file_no_content'))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[_c('router-link',{staticClass:"d-flex align-items-center justify-content-xl-between flex-1 h-100",attrs:{"to":_vm.jumpToFileDetailPage(index, false)}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleUpdaterName(file).name),expression:"handleUpdaterName(file).name",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",class:{
                'text-secondary-tint-less': file.user_active_status
              }},[_vm._v(" "+_vm._s(_vm.handleUpdaterName(file).text)+" ")]),_c('span',{staticClass:"flex-fixed px-3"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(file.updated_at))+" ")])]),_c('div',{staticClass:"minw--30"},[(!file.is_read)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(index)}]}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")]):_vm._e()])],1)],1)],1),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': _vm.isFromHome }})])}),_c('ModalError',{attrs:{"id":'modal-error-shared-folder-list',"errorMess":_vm.$t('common.message.uncatch_error')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }