

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EState, IDropDown } from '../Layout/SharedFolderLayout.vue'

@Component
export default class PcButtonArea extends Vue {
  @Prop() private roleEditFile!: boolean
  @Prop() private actionAreaState!: EState
  @Prop() private isSelectAnyItem!: boolean
  @Prop() private checkAll!: boolean
  @Prop() private sortSelected!: any
  @Prop() private sortOptions!: IDropDown[]
  @Prop() private filesLength!: number

  private checkAllClone: boolean = this.checkAll
  private isCommonTopic = !this.$route.params.groupId
  private eState = EState

  @Watch('checkAll')
  watchCheckAll() {
    this.checkAllClone = this.checkAll
  }

  handleMoveToUnclassified(isFromBin: boolean) {
    this.$emit('handleMoveToUnclassified', isFromBin)
  }

  openConfirmDeleteBin(isFromBin: boolean) {
    this.$emit('openConfirmDeleteBin', isFromBin)
  }

  handleSortChange(index: number) {
    this.$emit('handleSortChange', index)
  }

  handleCheckAll() {
    this.$emit('handleCheckAll', this.checkAllClone)
  }

  openEditFolderModal() {
    this.$emit('openEditFolderModal')
  }

  handleDownloadFiles() {
    this.$emit('handleDownloadFiles')
  }
}
